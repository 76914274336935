"use client";
import React, { Suspense } from "react";
import dynamic from "next/dynamic";

const SubscriptionPlanComponent = dynamic(() =>
  import("@/components/subscription-plan/SubscriptionPlanComponent")
);

export default function Registration() {
  function SearchBarFallback() {
    return <>placeholder</>;
  }
  return (
    <main>
       <Suspense fallback={<SearchBarFallback />}>
        <SubscriptionPlanComponent></SubscriptionPlanComponent>
       </Suspense>
    </main>
  );
}
